import Swiper from 'swiper';
import { EffectFade, Autoplay, Navigation } from "swiper/modules"
import { ScrollTrigger } from "gsap/all";

import 'swiper/css';
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

class ImageSlider extends HTMLElement {

  constructor() {
    super();

    const swiper = new Swiper(this.querySelector('.swiper'), {
      modules: [EffectFade, Autoplay, Navigation],
      autoplay: {
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        delay: 4500,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      effect: "fade",
      fadeEffect: { crossFade: true },
      slidesPerView: 1,
      speed: 1500,
      loop: true,
    });

    swiper.on('afterInit', () => {
      ScrollTrigger.refresh();
    });
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('image-slider', ImageSlider);
}
