import ScrollTrigger from "gsap/ScrollTrigger";

class SupportingProgram extends HTMLElement {

  constructor() {
    super();
    this.targets = this.querySelectorAll('[data-category]');
    if (!this.targets.length) {
      return;
    }

    this.triggers = this.querySelectorAll('[data-target-category]')
    this.state = {
      "einkaufen": true,
      "essen-trinken": true,
      "kultur-nachtleben": true,
    };

    if (this.triggers.length) {
      this.triggers.forEach((trigger) => {
        trigger.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();

          this.state[trigger.dataset.targetCategory] = !this.state[trigger.dataset.targetCategory];

          this.update()
        });
      });
    }
  }


  update() {
    this.triggers.forEach((trigger) => {
      trigger.classList.toggle('text-green-dark', this.state[trigger.dataset.targetCategory]);
      trigger.classList.toggle('bg-green-bright', this.state[trigger.dataset.targetCategory]);
      trigger.classList.toggle('text-green-bright', !this.state[trigger.dataset.targetCategory]);
      trigger.classList.toggle('bg-transparent', !this.state[trigger.dataset.targetCategory]);
    });

    // if (!(this.state["einkaufen"] == false && this.state["essen-trinken"] == false && this.state["kultur-nachtleben"] == false)) {
    //   this.targets.forEach((target) => {
    //     target.classList.toggle('hidden', !this.state[target.dataset.category]);
    //   });
    // }
    // else {
    //   this.targets.forEach((target) => {
    //     target.classList.toggle('hidden', false);
    //   });
    // }
    this.targets.forEach((target) => {
      target.classList.toggle('hidden', !this.state[target.dataset.category]);
    });

    ScrollTrigger.refresh();

  }


}


// Define the new web component
if ('customElements' in window) {
  customElements.define('supporting-program', SupportingProgram);
}
