// import van11yAccessibleTabPanelAria from "van11y-accessible-tab-panel-aria";
// import ShowMore from "./features/show-more";
// import LinkNextElement from "./features/link-next-element";

import SupportingProgram from "./components/supporting-program";
import ImageSlider from "./components/image-slider";

import Loader from "./features/loader";
import Lights from "./features/lights";
import Video from "./features/video";
import Reveal from "./features/reveal";
import MainzLeuchtetAccordion from "./features/accordion";
// import Header from "./features/header";
import NavMobile from "./features/nav-mobile";
import Jumpmenu from "./features/jumpmenu";
new MainzLeuchtetAccordion('.js--accordion');

// // https://kenwheeler.github.io/slick/

// new Header();
new Loader();
new Lights();
new Video('.js--video');
new Reveal('.js--reveal');
new MainzLeuchtetAccordion('.js--accordion');
new NavMobile();
new Jumpmenu('.js--jumpmenu');
// new VantageAccordion('.js--accordion');

document.documentElement.classList.replace('no-js', 'js');
